// src/directives/v-outside-click.js
export default {
    mounted(el, binding) {
        const handleClick = (event) => {
            if (!el.contains(event.target)) {
                if (typeof binding.value === 'function') {
                    binding.value();
                } else {
                    console.error('Directive value is not a function');
                }
            }
        };

        document.addEventListener('click', handleClick);
        el._clickOutsideHandler = handleClick;
    },
    unmounted(el) {
        document.removeEventListener('click', el._clickOutsideHandler);
    },
};
