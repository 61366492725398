<template>
  <div class="mp__datepicker"    v-outside-click="() => hideDatepicker()">
    <div @click="openDatepicker()"  ref="dropdownContent">
      <div v-if="datePickerMode === 'range'" class="date-input">
        <div class="date-input-button"  :class="{hasSelection: rangeStartDate }">
          <svg class="date-input-icon"><use xlink:href="#icon-calendar"></use></svg>
          <span class="date-input-label">{{ rangeStartDate ? rangeStartDate.toFormat("dd.MM.") : 'von' }}</span>
          <svg v-if="rangeStartDate" @click.stop="clearDates" class="date-input-input-clear">
            <use xlink:href="#icon-close-circle"></use>
          </svg>
        </div>
        <div class="date-input-button" :class="{hasSelection: rangeEndDate }">
          <svg class="date-input-icon"><use xlink:href="#icon-calendar"></use></svg>
          <span class="date-input-label">{{ rangeEndDate ? rangeEndDate.toFormat("dd.MM.yy") : 'bis' }}</span>
          <svg v-if="rangeEndDate" @click.stop="clearDates" class="date-input-input-clear">
            <use xlink:href="#icon-close-circle"></use>
          </svg>
        </div>
      </div>
      <div v-else-if="datePickerMode === 'multi-date'" class="date-input">
        <div class="date-input-button" :class="{hasSelection: selectedDates.length }">
          <svg class="date-input-icon"><use xlink:href="#icon-calendar"></use></svg>
          <span class="date-input-label">{{ selectedDates.length ? formattedDates() : 'Wann'}}</span>

          <svg v-if="selectedDates.length > 1 || (selectedDates.length === 1 && (props.hideTodayClearIcon && !isTodaySelected())) || !props.hideTodayClearIcon && selectedDates.length !== 0" @click.stop="clearDates" class="date-input-input-clear">
            <use xlink:href="#icon-close-circle"></use>
          </svg>
        </div>
      </div>
      <div v-else class="date-input">
        <div class="date-input-button">
          <svg class="date-input-icon"><use xlink:href="#icon-calendar"></use></svg>
          <span class="date-input-label">{{ 'Wann' }}</span>
        </div>
      </div>
    </div>
    <div v-show="showDatePicker" class="datepicker" :style="dropdownStyle">
      <div v-if="datePickerMode" class="controls">
        <button @click="prevMonth" class="control-button"><svg class="icon"><use xlink:href="#icon-arrow-left"></use></svg></button>
        <span class="current-month">{{ currentMonth }}</span>
        <button @click="nextMonth" class="control-button"><svg class="icon"><use xlink:href="#icon-arrow-right"></use></svg></button>
      </div>

      <div v-if="!props.disableModeSelect" class="modes">
        <div class="weekdays__selection">
          <button
              v-for="d in next7Days"

              :key="d.date.isoDate"
              @click="singleDay(d.date)"
              :class="{ active: selectedDates && selectedDates.length === 1 &&  selectedDates.includes(d.isoDate) }"
          >
            {{ d.title }}
          </button>
        </div>
        <div class="mode-buttons">
          <button @click="toggleDatePickerMode('multi-date')" class="mode-button" :class="{isSelected: datePickerMode === 'multi-date'}">Einzelne Tage</button>
          <button @click="toggleDatePickerMode('range')" class="mode-button" :class="{isSelected: datePickerMode === 'range'}">Zeitraum</button>

        </div>
      </div>
      <div v-if="props.disableModeSelect" class="modes">
        <div class="weekdays__selection">
          <button
              v-for="d in next7Days"

              :key="d.date.isoDate"
              @click="singleDay(d.date)"
              :class="{ active: selectedDates && selectedDates.length === 1 &&  selectedDates.includes(d.isoDate) }"
          >
            {{ d.title }}
          </button>
        </div>
      </div>
      <div v-if="datePickerMode" class="weeks">
        <div class="week">
          <div
              class="day weekday"
              v-for="weekday in weekdays"
              :key="weekday"
          >
            {{ weekday }}
          </div>
        </div>
        <div class="week" v-for="week in weeks" :key="week">
          <div
              class="day"
              v-for="day in week"
              :key="day.date.toFormat('yyyy-MM-dd')"

              @click.stop="toggleDate(day.date)"
              :class="{
                selected: isSelected(day.date),
                inRange: isInRange(day.date) ,
                'rangeStart': isRangeStart(day.date),
                'rangeEnd': isRangeEnd(day.date),
                'isNotInMonth' : !day.isInCurrentMonth,
                'disabled': disabledDates(day.date),
                'today': isToday(day.date) // Hervorhebung für heute
              }"
          >
            {{ day.date.day }}
          </div>
        </div>
      </div>
      <div v-if="datePickerMode" class="actions">
        <button @click="hideDatepicker">abbrechen</button>
        <button  class="primary" @click="submitDates" >übernehmen</button>

      </div>
    </div>
  </div>
</template>


<script setup>
import { DateTime, Settings } from "luxon";
import {debounce} from "lodash";
import {computed, ref, defineEmits, defineProps, onMounted, onUnmounted, watch} from "vue";

// Luxon auf Deutsch einstellen
Settings.defaultLocale = 'de';

const emit = defineEmits(["getDates", "open", "close"])
const props = defineProps({
  days: {
    type: Array,

    default: () => ([])
  },
  disableModeSelect: {
    type: Boolean,
    default: false
  },
  hideTodayClearIcon: {
    type: Boolean,
    default: false
  },
  defaultToday: {
    type: Boolean,
    default: false
  },
  mode: {
    type: String,
    default: null
  }
});

const next7Days = ref([]);
const dropdownContent = ref(null)
const dropdownStyle = ref({})

const showDatePicker = ref(false);
const currentDate = ref(DateTime.now());
const selectedMonth = ref(currentDate.value.month);
const selectedYear = ref(currentDate.value.year);

const datePickerMode = ref(props.mode); // Standardmodus
const currentMonth = computed(() => {
  return currentDate.value.toFormat('MMMM yyyy');
});

const selectedDates = ref([]); // Array zur Speicherung ausgewählter Daten
const rangeStartDate = ref(null); // Startdatum des Bereichs
const rangeEndDate = ref(null); // Enddatum des Bereichs
const singleDay = (day) => {

  if (day) {
    // Überprüfen Sie, ob der ausgewählte Tag im aktuellen Monat und Jahr liegt
    const formattedDate = day.toFormat('yyyy-MM-dd');
    selectedDates.value = [formattedDate]
    showDatePicker.value = false

    emit("close")
    dropdownStyle.value = {display: showDatePicker.value ? 'block' : 'none'}
    emit("getDates", {
      days: selectedDates.value.map(d => DateTime.fromISO(d).toJSDate()),
      isRange: false
    })
  }

}
const getNext7Days = () => {
  const today = DateTime.now();
  for (let i = 0; i < 7; i++) {
    const currentDate = today.plus({days: i});
    const title = i === 0 ? 'heute' : i === 1 ? 'morgen' : currentDate.toLocaleString({weekday: 'short', locale: 'de'});
    next7Days.value.push({
      title: title,
      jsDate: currentDate.toJSDate(),
      date: currentDate,
      isoDate: currentDate.toISODate(),
    });
  }

};
const handleScroll = debounce(() => {
  if (window.innerWidth <= 560) {
    const rect = dropdownContent.value.getBoundingClientRect();
    const dropdownTop = rect.bottom  + 10; // Berücksichtigen Sie die vertikale Scrollposition

    // Positionieren Sie das Dropdown-Menü 10px unterhalb des Auslöser-Elements
    dropdownStyle.value = {
      display: showDatePicker.value ? 'block' : 'none',
      top: dropdownTop + 'px'
    };


  } else {
    dropdownStyle.value = {display: showDatePicker.value ? 'block' : 'none'}; // Leer den Stil, um die Standardpositionierung zu verwenden
  }
}, 30); // 100ms Verzögerung
const openDatepicker = () => {
  showDatePicker.value = true;
  emit('open')
  handleScroll()

}
function isValidDate(isoString) {
  const dt = DateTime.fromISO(isoString);
  return dt.isValid;
}
const initializeSelectedDates = () => {

  if (props.days && props.days.length > 0) {
    if (props.mode === 'range') {
      // Überprüfe, ob das erste Datum gültig ist
      if (isValidDate(props.days[0])) {
        rangeStartDate.value = DateTime.fromISO(props.days[0]);
      } else {
        rangeStartDate.value = null;
      }
      // Überprüfe, ob das zweite Datum vorhanden und gültig ist
      if (props.days.length > 1 && isValidDate(props.days[1])) {
        rangeEndDate.value = DateTime.fromISO(props.days[1]);
      } else {
        rangeEndDate.value = null;
      }
      datePickerMode.value = 'range';
    } else {
      // Filtere ungültige Daten und konvertiere die gültigen
      selectedDates.value = props.days.filter(day => isValidDate(day)).map(day => DateTime.fromISO(day).toFormat('yyyy-MM-dd'));
      datePickerMode.value = 'multi-date';
    }
  } else {
    selectedDates.value = [];
    rangeStartDate.value = null;
    rangeEndDate.value = null;
  }
};

onMounted(() => {
  initializeSelectedDates();
});

watch(() => props.days, () => {
  initializeSelectedDates();
});

const hideDatepicker = () => {
  emit("close")
  dropdownStyle.value = {}
  showDatePicker.value = false
}


const disabledDates = (day) => {
  const today = DateTime.now();
  return day < today.startOf('day') || day < today.startOf('month');
};
const formattedDates = () => {
  const formatDatePart = (dateString) => {

    if(dateString){
      const date = new Date(dateString);

      return date ? date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' }) : '';
    }
    return '';
  };
  const formattedStart = formatDatePart(selectedDates.value[0]);
  const rangeInfo = selectedDates.value.length > 1 ? ` (+${selectedDates.value.length - 1})` : '';

  return `${formattedStart}${rangeInfo}`;


};

const toggleDatePickerMode = (mode) => {
  if (mode === 'range') {
    selectedDates.value = []
    datePickerMode.value = mode;
  } else {
    selectedDates.value = []
    rangeStartDate.value = null
    rangeEndDate.value = null
    datePickerMode.value = mode;
  }
};

const prevMonth = () => {
  currentDate.value = currentDate.value.minus({ months: 1 });
  selectedMonth.value = currentDate.value.month; // Aktualisiere den ausgewählten Monat
  selectedYear.value = currentDate.value.year; // Aktualisiere das ausgewählte Jahr
  weeks.value = generateWeeksArray();
};

const nextMonth = () => {
  currentDate.value = currentDate.value.plus({ months: 1 });
  selectedMonth.value = currentDate.value.month; // Aktualisiere den ausgewählten Monat
  selectedYear.value = currentDate.value.year; // Aktualisiere das ausgewählte Jahr
  weeks.value = generateWeeksArray();
};

const isRangeStart = (day) => {
  return day && datePickerMode.value === 'range' && rangeStartDate.value && day.equals(rangeStartDate.value);
};

const isRangeEnd = (day) => {
  return day && datePickerMode.value === 'range' && rangeEndDate.value && day.equals(rangeEndDate.value);
};

const toggleDate = (_day) => {


  if(disabledDates(_day)){
    return;
  }
  // Funktion zum Hinzufügen oder Entfernen des ausgewählten Tages je nach Modus
  if (_day && datePickerMode.value === 'range') {
    // Range-Modus: Start- und Enddatum auswählen
    if (!rangeStartDate.value || (_day <= rangeStartDate.value && rangeEndDate.value)) {
      rangeStartDate.value = _day;
      rangeEndDate.value = null;
    } else if (!rangeEndDate.value || (_day >= rangeStartDate.value && _day <= rangeEndDate.value)) {
      if (_day < rangeStartDate.value) {
        rangeEndDate.value = rangeStartDate.value;
        rangeStartDate.value = _day;
      } else {
        rangeEndDate.value = _day;
      }
    }else {
      rangeStartDate.value = _day;
      rangeEndDate.value = null;
    }
  } else if (_day) {
    // Überprüfen Sie, ob der ausgewählte Tag im aktuellen Monat und Jahr liegt
    const formattedDate = _day.toFormat('yyyy-MM-dd');
    if (isSelected(_day)) {
      // Wenn der Tag bereits ausgewählt ist, entfernen Sie ihn aus dem Array
      selectedDates.value = selectedDates.value.filter((date) => date !== formattedDate);
    } else {
      // Andernfalls fügen Sie den Tag dem Array hinzu
      selectedDates.value.push(formattedDate);
    }
  }
};

const isSelected = (_day) => {
  // Überprüfen Sie, ob der Tag ausgewählt ist
  return selectedDates.value.includes(_day.toFormat('yyyy-MM-dd'))
};

const isInRange = (day) => {
  // Überprüfen Sie, ob der Tag innerhalb des ausgewählten Bereichs liegt
  return (
      day &&
      datePickerMode.value === 'range' &&
      rangeStartDate.value &&
      rangeEndDate.value &&
      day >= rangeStartDate.value &&
      day <= rangeEndDate.value
  );
};

const weekdays = computed(() => {
  return ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
});


const generateWeeksArray = () => {
  const weeks = [];
  const firstDayOfMonth = DateTime.local(selectedYear.value, selectedMonth.value, 1);
  const lastDayOfMonth = firstDayOfMonth.plus({ months: 1 }).minus({ days: 1 });

  // Determine the starting day for the first week
  let currentWeekStart = firstDayOfMonth.minus({ days: firstDayOfMonth.weekday - 1 });

  while (currentWeekStart <= lastDayOfMonth) {
    const week = getDaysInWeek(currentWeekStart);
    weeks.push(week);
    currentWeekStart = currentWeekStart.plus({ weeks: 1 });
  }

  return weeks;
};
const weeks = ref(generateWeeksArray()) // Wochen beim ersten Laden erstellen
const isToday = (day) => {
  const today = DateTime.now();
  return day.hasSame(today, 'day');
};

const isTodaySelected = () => {
  if (selectedDates.value.length === 1) {
    const today = DateTime.now().startOf('day'); // Das aktuelle Datum, auf Mitternacht gesetzt
    const selectedDate = DateTime.fromISO(selectedDates.value[0]).startOf('day'); // Das ausgewählte Datum, auf Mitternacht gesetzt

    return  today.equals(selectedDate);
  }

  return false

}

function getDaysInWeek(weekStart) {
  const firstDay = weekStart;
  const lastDay = weekStart.plus({ days: 6 }); // Assuming a week has 7 days

  const daysInWeek = [];
  let currentDay = firstDay;

  while (currentDay <= lastDay) {
    if (currentDay.month === selectedMonth.value) {
      daysInWeek.push({ date: currentDay, isInCurrentMonth: true });
    } else {
      daysInWeek.push({ date: currentDay, isInCurrentMonth: false });
    }
    currentDay = currentDay.plus({ days: 1 });
  }

  return daysInWeek;
}


const submitDates = () => {
  showDatePicker.value = false

  emit("close")
  dropdownStyle.value = {display: showDatePicker.value ? 'block' : 'none'}

  if(datePickerMode.value === 'range' && rangeStartDate.value){

    if(rangeEndDate.value){
      emit("getDates", {
        days: [rangeStartDate.value.toJSDate(), rangeEndDate.value.toJSDate()],
        isRange: true
      })
    }else{
      emit("getDates", {
        days: [rangeStartDate.value.toJSDate()],
        isRange: true
      })
    }

  }else if(selectedDates.value.length){
    emit("getDates", {
      days: selectedDates.value.map(d => DateTime.fromISO(d).toJSDate()),
      isRange: false
    })

  }
}

const clearDates = () => {
  rangeStartDate.value = null
  rangeEndDate.value = null

  if(props.defaultToday){
    selectedDates.value = [ DateTime.now().toISODate()]
    emit("getDates", {
      days: [ DateTime.now().toJSDate()],
      isRange: false
    })
  }else{
    selectedDates.value = []
    emit("getDates", {
      days: [],
      isRange: false
    })
  }


  dropdownStyle.value = {display: showDatePicker.value ? 'block' : 'none'}
  emit("close")
  showDatePicker.value = false
}


const handleWindowResize = () => {
  if (window.innerWidth > 560) {
    dropdownStyle.value = {
      display: showDatePicker.value ? 'block' : 'none'
    };
  }
};

onMounted(() => {
  getNext7Days()
  window.addEventListener('resize', handleWindowResize);
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleWindowResize);
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
.mp__datepicker {
position: relative;
}
.date-input {
display: flex;
gap: 10px;

&-button {
font-size: 14px;
border-radius: 25px;
padding: 0 10px;
height: 32px;
border: none;
background: #dadada;
cursor: pointer;
display: flex;
  flex-shrink: 0;
color: #4B4B4B;
font-weight: 600;
align-items: center;
justify-content: center;

&.hasSelection {
background: #af255b;
color: #fff;

svg {
&.date-input-input-clear {
fill: #fff;
}
fill: #fff;
}
}

svg {
height: 17px;
width: 17px;
margin-right: 5px;

&.date-input-input-clear {
margin-left: 5px;
margin-right: 0;
fill: #4B4B4B;
}
}
}
}

.datepicker {
position: absolute;
top: 120%;
border: 1px solid #ccc;
border-radius: 5px;
padding: 10px;
width: 300px;
  right: 0;
z-index: 10;
background-color: #fff;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.controls {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 10px;
}

.control-button {
color: #fff;
height: 30px;
width: 30px;
padding: 0;
margin: 0;
border: none;
background: none;
border-radius: 50%;
cursor: pointer;
svg {
width: 20px;
height: 20px;
display: block;
margin: 0 auto;
}
}

.current-month {
font-size: 18px;
font-weight: bold;
}

.weeks {
display: flex;
flex-direction: column;
gap: 5px;
justify-content: space-between; /* Verteilt den verfügbaren Platz gleichmäßig zwischen den Kindern */
}

.week {
display: flex;
justify-content: space-between; /* Verteilt den verfügbaren Platz gleichmäßig zwischen den Kindern */
}

.day {
background-color: #fff;
border: 2px solid transparent;
border-radius: 50%;
height: 30px;
width: 30px;
  font-size: 14px;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
cursor: pointer;

&.weekday {
border-color: transparent;
}

&.disabled {
cursor: not-allowed;
opacity: 0.3;
}

&.today {
font-weight: bold; /* Fettschrift für heute */
color: #af255b;
}
}
.day:hover {
border: 2px solid #dadada;
}
.day.selected {
background-color: #af255b;
color: #fff;
border: 2px solid #af255b;

&:hover {
border-color: #af255b;
}
}

.day.inRange {
background-color: #dadada;
}

.day.rangeStart,
.day.rangeEnd {
background-color: #af255b;
color: #fff;
}
.isNotInMonth {
opacity: 0.3;
}

.weekday {
font-weight: bold;
}

.actions {
display: flex;
border-top: 1px solid #dadada;
padding-top: 20px;
gap: 10px;
margin: 20px 0 0 0;
justify-content: flex-end;
button {
padding: 10px 20px;
font-size: 13px;
font-weight: 600;
border: none;
background: none;
border-radius: 20px;
cursor: pointer;

&.primary {
background-color: #dadada;
color: #4B4B4B;
}
}
}

.modes {
display: flex;
flex-wrap: wrap;
gap: 10px;
margin: 20px 0;
.weekdays__selection {
display: none;
width: 100%;
flex: 1;
justify-content: space-between; /* Verteilt die Kinder mit maximalem Abstand */

button {
padding: 0 10px;
}
}
.mode-buttons {
display: flex;
flex-wrap: wrap;
gap: 10px; /* Definiert einen Abstand zwischen den Kindern */
justify-content: space-between; /* Verteilt die Kinder mit maximalem Abstand */
width: 100%;
}
.mode-button {
background-color: #dadada;
width: 100%;
padding: 6px 20px;
flex: 1;
color: #4B4B4B;
  font-size: 13px;
font-weight: 600;
border: none;
border-radius: 20px;
cursor: pointer;

&.isSelected {
background: #af255b;
color: #fff;
}
}
}
@media (max-width: 700px) {
.modes {
.weekdays__selection {
display: flex;
}
}
}
@media (max-width: 560px) {
.datepicker {
position: fixed;
width: 92%;
left: 2%;
}
.day {
height: 28px;
width: 28px;
}
.modes .weekdays__selection button {
padding: 5px 10px;
height: auto;
}
.modes .mode-button {
padding: 5px 20px;
height: auto;
}

.actions {
margin-top: 10px;
padding-top: 10px;
}
}
</style>
