// src/apollo-client.js

import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core';

const httpLink = new HttpLink({
    uri: 'https://api.meinplaner.com/graphql', // Ersetzen Sie dies durch Ihre tatsächliche GraphQL-Endpoint-URL
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
});
