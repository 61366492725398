import {createRouter, createWebHashHistory} from "vue-router";
import EventList from "@/components/EventList.vue";

const routes = [
    // Weitere Routen hinzufügen
    { path: '/', component: EventList },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;