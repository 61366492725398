<template>
  <div id="mp__event__module">
    <template v-if="loading">
      <div v-if="loading" class="loading-spinner">
        <div class="spinner"></div>
      </div>
    </template>
    <template v-else>
    <div class="event__filter">
<div class="event_filter__grid">

  <div class="event__filter__headline">{{ totalEvents }} Veranstaltungen</div>
  <div class="filters">
    <div class="select-container" v-if="locations.length > 1">
      <select id="location-select" v-model="selectedLocation">
        <option value="">Alle Locations</option>
        <option v-for="location in locations" :key="location.uuid" :value="location.uuid">{{ location.title }}</option>
      </select>
    </div>
    <DatePicker @getDates="updateDates"  :hide-today-clear-icon="false" :days="days" :mode="isRange ? 'range' : 'multi-date'" />

  </div>
</div>
    </div>
    <div class="event__list" v-if="!loading && events.length > 0">
      <EventItem :key="event.id" v-for="event in events" :item="event">
      </EventItem>
    </div>
      <div v-else>
        <p>Es wurden keine Veranstaltungen gefunden.</p>
      </div>
    <button v-if="remainingEventsCount" @click="loadMoreEvents" class="load-more-button">
      {{ remainingEventsCount }} weitere Veranstaltungen laden
    </button>
    <div class="mp__footer">
      <a class="mp__footer__label" href="https://www.meinplaner.com" target="_blank">
        <span>Powered by</span>
        <svg id="mp_quadratAbgerEcken" xmlns="http://www.w3.org/2000/svg" width="140.9" height="140.9" viewBox="0 0 140.9 140.9">
          <rect id="Rechteck_1" data-name="Rechteck 1" width="140.9" height="140.9" rx="16.8" fill="#c6c6c6"/>
          <g id="zLSLXe.tif">
            <path id="Pfad_1" data-name="Pfad 1" d="M11.7,88.6V50c0-.8.4-1,1.1-1.1a26.18,26.18,0,0,0,5.6-.9,18.931,18.931,0,0,0,5.1-2.1,8.076,8.076,0,0,0,2.8-3A13.506,13.506,0,0,1,35.8,36a21.373,21.373,0,0,1,6.2-.4,15.531,15.531,0,0,1,11.2,6c.6.8,1.2,1.7,1.7,2.5a1.438,1.438,0,0,0,.2.3c.5-.5.9-1,1.4-1.6a25.1,25.1,0,0,1,4.4-4.2,16.1,16.1,0,0,1,7.6-2.9,22.863,22.863,0,0,1,12.4,1.8,15.112,15.112,0,0,1,7.9,8.9,19.394,19.394,0,0,1,1.3,5.4h0c.3-.3.6-.7.9-1a18.906,18.906,0,0,1,10.7-5.6,22.831,22.831,0,0,1,6.9-.2,23.563,23.563,0,0,1,17.8,11.2,29.088,29.088,0,0,1,3.8,9.9,39.552,39.552,0,0,1,.7,5.9c.1,8.5-2.7,15.8-9,21.6a22.949,22.949,0,0,1-11.6,5.6,24.3,24.3,0,0,1-13.6-1.4,16.374,16.374,0,0,1-5.9-4.2l-.2-.2h0v21.4H75.2V60.4a21.588,21.588,0,0,0-1-6.6,5.237,5.237,0,0,0-4.3-3.9,13,13,0,0,0-5.2,0,7.154,7.154,0,0,0-5.2,5.4,11.236,11.236,0,0,0-.4,3.2V88.1H44.4c-.6,0-.8-.3-.8-.9V58.5a13.046,13.046,0,0,0-1.4-5.8,5.266,5.266,0,0,0-4.2-3,9.851,9.851,0,0,0-5.1.3,7.19,7.19,0,0,0-4.5,4.5,10.879,10.879,0,0,0-.7,4c0,2-.1,3.9-.1,5.9V88H12.1ZM115.5,72.5a13,13,0,1,0-12.9,13,12.805,12.805,0,0,0,12.8-13h0Z" fill="#fff"/>
          </g>
          <g id="Gruppe_2" data-name="Gruppe 2" style="isolation: isolate">
            <g id="Gruppe_1" data-name="Gruppe 1" style="isolation: isolate">
              <path id="Pfad_2" data-name="Pfad 2" d="M119.2,39.9a6.619,6.619,0,0,1,6.5-6.5,6.814,6.814,0,0,1,6.7,6.6,6.6,6.6,0,1,1-13.2-.1Zm11.7.2a5.151,5.151,0,1,0-5.1,5.2,5.194,5.194,0,0,0,5.1-5.2ZM127,43.6c0-.1-.1-.4-.2-.8v-.9a1.181,1.181,0,0,0-1.3-1.2h-.8v2.8h-1.8V36.2H126c1.6,0,2.6.7,2.6,2.1s-.4,1.4-1.2,1.8c.7.3,1,.8,1.1,2.1a2.571,2.571,0,0,0,.2,1.4H127Zm-1.3-4c.7,0,1.2-.3,1.2-1.1s-.4-.9-1.1-.9h-1v2.1h.9Z" fill="#fff"/>
            </g>
          </g>
        </svg>


      </a>
    </div>
    <div v-if="error">Fehler beim Abrufen der Veranstaltungen</div>
    </template>
  </div>
  <AppIcons></AppIcons>
</template>
<script setup>
import { reactive, ref, defineProps, watch, computed, onMounted, watchEffect, nextTick } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import EventItem from "@/components/EventItem.vue";
import AppIcons from "@/components/AppIcons.vue";
import DatePicker from "@/components/form/date-picker.vue";
import { useRoute, useRouter } from "vue-router";
import { DateTime } from "luxon";
import { GET_EVENTS } from "@/gql/query";

const props = defineProps({
  apiKey: String
});
const variables = reactive({
  pageSize: 10,
  after: null,
  date: {
    from: new Date().toISOString().split('T')[0] // Setzt das heutige Datum
  },
  apiKey: props.apiKey
});

const route = useRoute();
const router = useRouter();
const selectedLocation = ref(route.query.location || '');
const locations = ref([]);

const isRange = ref(route.query.isRange === '1');
const days = ref(route.query.days?.split(',') || []);
const range = ref(route.query.isRange === '1');
const dates = ref(route.query.days ? route.query.days.split(',').map((isoDate) => DateTime.fromISO(isoDate + 'T00:00:00.000Z').toJSDate()) : []);
const isInitialized = ref(false);

const initializeQueryData = async () => {
  await nextTick();
  const queryDays = route.query.days;
  const queryIsRange = route.query.isRange;
  const queryLocation = route.query.location;

  console.log('Initializing Query Data', { queryDays, queryIsRange, queryLocation });

  if (queryDays) {
    dates.value = queryDays.split(',').map((isoDate) => DateTime.fromISO(isoDate + 'T00:00:00.000Z').toJSDate());
    days.value = queryDays.split(',');
  } else {
    dates.value = [];
    days.value = [];
  }

  isRange.value = queryIsRange === '1';
  range.value = queryIsRange === '1';
  selectedLocation.value = queryLocation || '';

  isInitialized.value = true;
}

const updateDates = (_dates) => {
  if (!_dates || !_dates.days) {
    throw new Error("Invalid _dates object");
  }
  range.value = _dates.isRange;
  isRange.value = _dates.isRange;
  dates.value = _dates.days;
  days.value = dates.value.map(date => DateTime.fromJSDate(new Date(date)).toISODate());

  console.log('Updating Dates', { range: range.value, isRange: isRange.value, dates: dates.value, days: days.value });

  updateRouterState();
}

watch(selectedLocation, () => {

  updateRouterState();
})

const updateRouterState = () => {
  if (!isInitialized.value) {
    return;
  }

  const queryString = days.value.join(',');
  const isRangeValue = isRange.value ? '1' : '0';

  let newQuery = {
    ...route.query,
    days: queryString,
    location: selectedLocation.value,
    isRange: queryString ? isRangeValue : null,
  };

  for (const key in newQuery) {
    if (newQuery[key] === null || newQuery[key] === undefined || newQuery[key] === '') {
      delete newQuery[key];
    }
  }

  console.log('Updating Router State', newQuery);

  router.push({ path: route.path, query: newQuery }).catch(err => { console.error(err) });
};

const totalEvents = ref(0);

const { result, loading, error, fetchMore } = useQuery(GET_EVENTS, variables, {
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'network-only'
});

const events = ref([]);
watch(result, () => {
  if (result.value) {
    totalEvents.value = result.value.apiEvents.totalCount;
    events.value = result.value.apiEvents.edges.map(edge => edge.node);
    locations.value = result.value.publicApiKey.locations.edges.map(edge => edge.node);
  }
})

watch(
    () => route.query,
    async (newQuery) => {
      await nextTick();
      const queryDays = newQuery.days;
      const queryIsRange = newQuery.isRange;
      const queryLocation = newQuery.location;

      console.log('Route Query Watch', { queryDays, queryIsRange, queryLocation });

      if (queryDays) {
        dates.value = queryDays.split(',').map((isoDate) => DateTime.fromISO(isoDate + 'T00:00:00.000Z').toJSDate());
        days.value = queryDays.split(',');
      } else {
        dates.value = [];
        days.value = [];
      }

      isRange.value = queryIsRange === '1';
      range.value = queryIsRange === '1';
      selectedLocation.value = queryLocation || '';
    },
    { immediate: true }
);

const formatDate = date => {
  if (!(date instanceof Date)) {
    return '';
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

watchEffect(() => {
  if (!isInitialized.value) {
    return;
  }

  if (isRange.value && dates.value.length === 2) {
    variables.date.from = formatDate(dates.value[0]);
    variables.date.to = formatDate(dates.value[1]);
    variables.day_list = [];
  } else {
    variables.date.from = null;
    variables.date.to = null;
    variables.day_list = days.value;
  }

  if (selectedLocation.value) {
    variables.location = selectedLocation.value;
  } else {
    variables.location = null;
  }


});

const loadedEventsCount = ref(events.value.length);
const hasMoreEvents = computed(() => loadedEventsCount.value < totalEvents.value);

const remainingEventsCount = computed(() => totalEvents.value - events.value.length);

const loadMoreEvents = () => {
  if (!hasMoreEvents.value) return;

  fetchMore({
    variables: {
      after: result.value.apiEvents.pageInfo.endCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      const newEdges = fetchMoreResult.apiEvents.edges;
      const pageInfo = fetchMoreResult.apiEvents.pageInfo;

      return newEdges.length ? {
        ...previousResult,
        apiEvents: {
          ...previousResult.apiEvents,
          // Concat edges
          edges: [
            ...previousResult.apiEvents.edges,
            ...newEdges,
          ],
          // Override with new pageInfo
          pageInfo,
        }
      } : previousResult;
    },
  })
};

onMounted(() => {
  initializeQueryData();
});
</script>






<style lang="scss" scoped>

/* pt-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/pt-sans-v17-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* pt-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/pt-sans-v17-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* pt-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/pt-sans-v17-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* pt-sans-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/pt-sans-v17-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
#mp__event__module{
  font-family: "PT Sans",serif;
  max-width: 900px;
  margin: 40px auto;
}
.event__list{
}

.event__filter{
  position: sticky;
  top: 0;
  margin-bottom: 20px;
  padding: 10px;
  background: #fff;
  border-bottom: 1px solid #dadada;
  z-index: 100;

  .filters{
    display: flex;
    align-items: center;
  }

  .event_filter__grid{
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    .event__filter__headline{
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.load-more-button {
  display: block;
  font-family: "PT Sans",serif;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #af255b;
  color: #fff;
  border: none;
  border-radius: 25px;
  font-weight: 700;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: #111;
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #af255b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.mp__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 40px 0;
}

.mp__footer__label {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;

  span {
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
  }

  svg {
    width: 40px;
    height: auto;
    fill: #333;
    transition: fill 0.3s ease;
  }

  &:hover svg {
    fill: #09f; // Farbe bei Hover-Effekt
  }
}

/* Button-Styling annehmen */
#location-select {
  width: 100%;
  padding: 8px 30px 8px 14px;
  border: 1px solid #ddd;
  border-radius: 25px; /* Runde Ecken hinzufügen */
  background-color: transparent; /* Hintergrundfarbe ähnlich zu Buttons */
  font-size: 14px; /* Schriftgröße anpassen */
  appearance: none; /* Standard-Styling des Browsers entfernen */
  -webkit-appearance: none; /* Für Safari */
  -moz-appearance: none; /* Für Firefox */
}

/* Optional: Arrow-Styling für das Select-Element */
.select-container::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Optional: Container für das Select-Element, um Arrow-Styling korrekt anzuzeigen */
.select-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-right: 12px;
}


</style>