import { createApp } from 'vue';
import EventList from './components/EventList.vue';

import { DefaultApolloClient } from '@vue/apollo-composable';
import { apolloClient } from './apollo-client';
import OutsideClickDirective from './directives/v-outside-click';
import router from "@/router";

class MpEventList {
    constructor(options) {
        const { apiKey, containerId } = options;
        this.apiKey = apiKey;
        this.containerId = containerId;
        this.mountApp();
    }

    mountApp() {

        const app = createApp(EventList, { apiKey: this.apiKey });
        app.provide( DefaultApolloClient, apolloClient);

        app.use(router);
        app.directive('outside-click', OutsideClickDirective);
        const mountPoint = document.getElementById(this.containerId);

        if (mountPoint) {
            app.mount(mountPoint);
        } else {
            console.error(`Container with id "${this.containerId}" not found.`);
        }
    }
}

// Global verfügbar machen
window.mpEventList = MpEventList;
console.log("mpEventList defined in main.js:", typeof window.mpEventList); // Debugging-Zeile
export default MpEventList;